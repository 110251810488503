/* eslint-disable @typescript-eslint/no-explicit-any */
import { LanguageCode, type LanguageCodeT } from '@prismo-io/schemas';
import { ApplicationRoutes } from '../config';
import type { Application } from '../types/application';
import { translate } from './translate';

type Props = {
  app: Application;
  locale: LanguageCodeT;
  pathname: string;
  params?: Record<string, string>;
};

export const decode = (props: Props): string => {
  const { app, pathname, locale = LanguageCode.Enum.fr, params } = props;

  const config = ApplicationRoutes[app];
  if (!config) {
    throw new Error(`${app} is not a valid value`);
  }

  if (!locale) {
    throw new Error(`${locale} is not defined`);
  }

  const translation: Record<string, string> = config.translates[
    locale
  ] as Record<string, string>;
  if (!translation) {
    throw new Error(`${locale} is not a valid value`);
  }

  const untranslatePathname = Object.entries(translation).find(
    ([key, _]) =>
      translate({ app, path: key, params, locale } as any) === pathname
  );
  if (!untranslatePathname) {
    return pathname;
    // throw new Error(`Translate not found for`);
  }

  return untranslatePathname[0];
};
