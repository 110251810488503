import { Stack } from '@mui/material';
import type { FC, ReactNode } from 'react';
type Props = {
  children: ReactNode;
  imgFirst?: boolean;
};
export const TowColsArticle: FC<Props> = (props: Props) => {
  const {
    children,
    imgFirst = false
  } = props;
  const direction = imgFirst ? 'row-reverse' : 'row';
  return <Stack direction={{
    none: 'column',
    md: direction
  }} gap={3} component="article" data-sentry-element="Stack" data-sentry-component="TowColsArticle" data-sentry-source-file="index.tsx">
      {children}
    </Stack>;
};