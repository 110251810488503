/* eslint-disable @typescript-eslint/naming-convention */
import type { RouteConfig } from '../types/route-config';

export const routes = {
  '/': {
    path: '/',
  },
  '/debug/connexion': {
    path: '/debug/connexion',
    queryParams: ['comingFrom'],
  },
  '/debug/inscription': {
    path: '/debug/inscription',
    queryParams: ['comingFrom'],
  },
  '/inscription': {
    path: '/inscription',
  },
  '/questionnaire': {
    path: '/questionnaire',
  },
  '/finalisation': {
    path: '/finalisation',
  },
  '/resultat-partenaire': {
    path: '/resultat-partenaire',
  },
} as const;

export const config: RouteConfig<typeof routes> = {
  defaultLocale: 'fr',

  routes,

  translates: {
    fr: {
      '/': '/',
      '/debug/connexion': '/debug/connexion',
      '/debug/inscription': '/debug/inscription',
      '/inscription': '/inscription',
      '/questionnaire': '/questionnaire',
      '/finalisation': '/finalisation',
      '/resultat-partenaire': '/resultat-partenaire',
    },
    en: {
      '/': '/',
      '/debug/connexion': '/debug/sign-in',
      '/debug/inscription': '/debug/sign-up',
      '/inscription': '/inscription',
      '/questionnaire': '/questionnaire',
      '/finalisation': '/finalization',
      '/resultat-partenaire': '/partner-result',
    },
  },
};
