'use client';

import { type Messages, setupI18n } from '@lingui/core';
import { i18n as i18nCore } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import type { LanguageCodeT } from '@prismo-io/schemas';
import { type FC, type PropsWithChildren, useState } from 'react';
type Props = {
  locale: LanguageCodeT;
  messages: Messages;
};
export const I18nClientProvider: FC<PropsWithChildren<Props>> = props => {
  const {
    locale,
    messages,
    children
  } = props;
  const [i18n] = useState(() => {
    i18nCore.loadAndActivate({
      locale,
      messages
    });
    return setupI18n({
      locale: locale,
      messages: {
        [locale]: messages
      }
    });
  });
  return <I18nProvider i18n={i18n} data-sentry-element="I18nProvider" data-sentry-component="I18nClientProvider" data-sentry-source-file="i18n-client.provider.tsx">{children}</I18nProvider>;
};