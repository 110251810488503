import { env } from '@prismo-io/env';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LanguageCode } from '@prismo-io/schemas';
import { ApplicationRoutes } from '../config';
import { Application } from '../types/application';
import type { AppPaths, LinkProps, QueryParamValue } from '../types/link';

const appHostnameMapper: Record<Application, string> = {
  [Application.APP]: env.NEXT_PUBLIC_APP_URL,
  [Application.AURA]: env.NEXT_PUBLIC_AURA_URL,
  [Application.HELIO]: env.NEXT_PUBLIC_HELIO_URL,
  [Application.QUARK]: env.NEXT_PUBLIC_QUARK_URL,
  [Application.ONBOARDING]: env.NEXT_PUBLIC_ONBOARDING_URL,
  [Application.TOOLS]: env.NEXT_PUBLIC_TOOLS_URL,
};

export const translate = <App extends Application, Path extends AppPaths<App>>(
  props: LinkProps<App, Path>
): string => {
  const {
    app,
    path,
    params = undefined,
    queryParams = undefined,
    addHostname = false,
  } = props;

  let { locale } = props;

  const config = ApplicationRoutes[app];
  if (!config) {
    throw new Error(`${app} is not a valid value`);
  }

  if (!locale) {
    locale = LanguageCode.Enum.fr;
    // throw new Error(`${locale} is not defined`);
  }

  const translate: Record<string, string> = config.translates[locale];
  if (!translate) {
    throw new Error(`${locale} is not a valid value`);
  }

  let translatePathname = translate[path as string];
  if (!translatePathname) {
    throw new Error(`Translate not found for ${path.toString()}`);
  }

  let href: string = translatePathname;

  // if (locale !== config.defaultLocale) {
  if (translatePathname.endsWith('/')) {
    translatePathname = translatePathname.substring(
      0,
      translatePathname.length - 1
    );
  }

  href = `/${locale}${translatePathname}`;
  // }

  if (params) {
    for (const [key, value] of Object.entries<string>(params as any)) {
      href = href.replace(new RegExp(`:${key}`, 'g'), value);
    }
  }

  if (queryParams) {
    const searchParams = new URLSearchParams();

    const assignSearchParams = (key: string, values: QueryParamValue[]) => {
      for (const value of values) {
        if (value === null || value === undefined) {
          continue;
        }

        if (Array.isArray(value)) {
          for (const sub of value) {
            assignSearchParams(key, [sub]);
          }
        } else {
          searchParams.append(key, value.toString());
        }
      }
    };

    const entries = Object.entries(queryParams);

    for (const [key, value] of entries) {
      assignSearchParams(key, [value]);
    }

    if (searchParams.toString().trim() !== '') {
      href = [href, '?', searchParams.toString()].join('');
    }
  }

  if (addHostname) {
    href = new URL(href, appHostnameMapper[app]).toString();
  }

  return href;
};
