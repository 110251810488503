import { ExpandMore } from '@mui/icons-material';
import StarIcon from '@mui/icons-material/Star';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
type Props = {
  title: string;
  more?: string;
  desc?: string;
  index?: string;
  canBeOpen?: boolean;
  isStar?: boolean;
};
export const ListItemAccordion: FC<Props> = (props: Props) => {
  const {
    title,
    more,
    desc = '',
    index = 0,
    canBeOpen = false,
    isStar = false
  } = props;
  return <Accordion disabled={!canBeOpen} sx={{
    border: '1px solid',
    borderColor: 'primary.main',
    borderRadius: '0 !important',
    boxShadow: 'none',
    '&.Mui-disabled': {
      backgroundColor: 'inherit'
    }
  }} data-sentry-element="Accordion" data-sentry-component="ListItemAccordion" data-sentry-source-file="index.tsx">
      <AccordionSummary expandIcon={canBeOpen ? <ExpandMore /> : null} aria-controls={`panel${index}-content`} id={`panel${index}-header`} sx={{
      borderBottom: '1px solid',
      borderColor: 'white',
      transition: 'borderColor ease-in-out 0.5s',
      '&.Mui-disabled': {
        opacity: '1'
      },
      '&.Mui-expanded': {
        minHeight: '48px',
        borderColor: '#e5e7eb'
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '12px 0'
      }
    }} data-sentry-element="AccordionSummary" data-sentry-source-file="index.tsx">
        <Stack width="100%" direction="row" justifyContent="space-between" data-sentry-element="Stack" data-sentry-source-file="index.tsx">
          <Stack direction="row" alignItems="center" gap={2} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
            <Typography fontWeight="bolder" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
              {index} | {title}
            </Typography>
            {isStar && <StarIcon fontSize="small" sx={{
            color: 'gold'
          }} />}
          </Stack>
          {more && <Typography fontWeight="bolder">{more}</Typography>}
        </Stack>
      </AccordionSummary>
      <AccordionDetails data-sentry-element="AccordionDetails" data-sentry-source-file="index.tsx">
        <Typography data-sentry-element="Typography" data-sentry-source-file="index.tsx">{desc}</Typography>
      </AccordionDetails>
    </Accordion>;
};