/* eslint-disable @typescript-eslint/naming-convention */
import type { RouteConfig } from '../types/route-config';

export const routes = {
  '/': {
    path: '/',
  },
  '/debug/connexion': {
    path: '/debug/connexion',
    queryParams: ['comingFrom'],
  },
  '/debug/inscription': {
    path: '/debug/inscription',
    queryParams: ['comingFrom'],
  },
  '/competences': {
    path: '/competences',
  },
  '/competences/:id': {
    path: '/competences/:id',
    params: ['id'],
  },
  '/experiences': {
    path: '/experiences',
  },
  '/experiences/:id': {
    path: '/experiences/:id',
    params: ['id'],
  },
  '/formations': {
    path: '/formations',
  },
  '/formations/:id': {
    path: '/formations/:id',
    params: ['id'],
  },
  '/appetences': {
    path: '/appetences',
  },
} as const;

export const config: RouteConfig<typeof routes> = {
  defaultLocale: 'fr',

  routes,

  translates: {
    fr: {
      '/': '/',
      '/debug/connexion': '/debug/connexion',
      '/debug/inscription': '/debug/inscription',
      '/experiences': '/experiences',
      '/experiences/:id': '/experiences/:id',
      '/formations': '/formations',
      '/formations/:id': '/formations/:id',
      '/competences': '/competences',
      '/competences/:id': '/competences/:id',
      '/appetences': '/appetences',
    },
    en: {
      '/': '/',
      '/debug/connexion': '/debug/sign-in',
      '/debug/inscription': '/debug/sign-up',
      '/experiences': '/experiences',
      '/experiences/:id': '/experiences/:id',
      '/formations': '/formations',
      '/formations/:id': '/formations/:id',
      '/competences': '/skills',
      '/competences/:id': '/skills/:id',
      '/appetences': '/appetences',
    },
  },
};
