import type { FC, PropsWithChildren } from 'react';
import { ColorModeProvider } from './color-mode/provider';
import { LocalizationProvider } from './localization.provider';
import { ThemeProvider, type Props as ThemeProviderProps } from './theme.provider';
type Props = ThemeProviderProps;
export const DesignSystemProvider: FC<PropsWithChildren<Props>> = props => {
  const {
    children,
    ...rest
  } = props;
  return <LocalizationProvider data-sentry-element="LocalizationProvider" data-sentry-component="DesignSystemProvider" data-sentry-source-file="design-system.provider.tsx">
      <ColorModeProvider data-sentry-element="ColorModeProvider" data-sentry-source-file="design-system.provider.tsx">
        <ThemeProvider {...rest} data-sentry-element="ThemeProvider" data-sentry-source-file="design-system.provider.tsx">
          {children}
          {/* <EasterEggProvider /> */}
        </ThemeProvider>
      </ColorModeProvider>
    </LocalizationProvider>;
};