import { Box, Stack, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import type { ComponentProps, FC, PropsWithChildren } from 'react';
type Props = {
  wrapper?: ComponentProps<typeof Box>;
};
export const MarkdownRenderer: FC<PropsWithChildren<Props>> = props => {
  const {
    children,
    wrapper = {}
  } = props;
  if (!children) {
    return null;
  }
  return <Markdown options={{
    wrapper: ({
      children
    }) => <Box component="div" {...wrapper}>
            {children}
          </Box>,
    forceWrapper: true,
    overrides: {
      p: Typography,
      h1: {
        component: Typography,
        props: {
          variant: 'h1'
        }
      },
      h2: {
        component: Typography,
        props: {
          variant: 'h2'
        }
      },
      h3: {
        component: Typography,
        props: {
          variant: 'h3'
        }
      },
      ul: {
        component: Stack,
        props: {
          component: 'ul',
          gap: 1
        }
      },
      li: {
        component: Typography,
        props: {
          component: 'li'
        }
      },
      ol: {
        component: Typography,
        props: {
          component: 'ol'
        }
      },
      strong: {
        component: Typography,
        props: {
          component: 'strong',
          fontWeight: 'medium'
        }
      },
      Typography: {
        component: Typography
      }
    }
  }} data-sentry-element="Markdown" data-sentry-component="MarkdownRenderer" data-sentry-source-file="index.tsx">
      {children as unknown as string}
    </Markdown>;
};