import { Application } from '../types';
import { config as AppConfig } from './app.routes';
import { config as AuraConfig } from './aura.routes';
import { config as HelioConfig } from './helio.routes';
import { config as OnboardingConfig } from './onboarding.routes';
import { config as QuarkConfig } from './quark.routes';
import { config as ToolsConfig } from './tools.routes';

export const ApplicationRoutes = {
  [Application.APP]: AppConfig,
  [Application.AURA]: AuraConfig,
  [Application.HELIO]: HelioConfig,
  [Application.QUARK]: QuarkConfig,
  [Application.ONBOARDING]: OnboardingConfig,
  [Application.TOOLS]: ToolsConfig,
};
