import { Stack } from '@mui/material';
import type { FC, ReactNode } from 'react';
type Props = {
  children: ReactNode;
  backgroundColor?: string;
  minHeight?: string;
  justifyContent?: string;
};
export const SectionArticle: FC<Props> = (props: Props) => {
  const {
    children,
    backgroundColor = 'transparent',
    minHeight = 'unset',
    justifyContent = 'flex-start'
  } = props;
  return <Stack direction="column" justifyContent={justifyContent} spacing={3} component="article" position="relative" minHeight={minHeight} paddingX={{
    none: '0.5rem',
    md: '1.5rem',
    lg: '4rem'
  }} paddingY={backgroundColor !== 'transparent' ? '1.5rem' : '0'} bgcolor={backgroundColor} borderRadius={{
    none: '1.5rem',
    md: '3rem'
  }} data-sentry-element="Stack" data-sentry-component="SectionArticle" data-sentry-source-file="index.tsx">
      {children}
    </Stack>;
};