/* eslint-disable @typescript-eslint/naming-convention */
import type { RouteConfig } from '../types/route-config';

export const routes = {
  '/': {
    path: '/',
  },
  '/debug/connexion': {
    path: '/debug/connexion',
    queryParams: ['comingFrom'],
  },
  '/debug/inscription': {
    path: '/debug/inscription',
    queryParams: ['comingFrom'],
  },
  '/avant-de-commencer': {
    path: '/avant-de-commencer',
  },
  '/questionnaire': {
    path: '/questionnaire',
  },
  '/finalisation': {
    path: '/finalisation',
  },
  '/resultats': {
    path: '/resultats',
  },
} as const;

export const config: RouteConfig<typeof routes> = {
  defaultLocale: 'fr',

  routes,

  translates: {
    fr: {
      '/': '/',
      '/debug/connexion': '/debug/connexion',
      '/debug/inscription': '/debug/inscription',
      '/avant-de-commencer': '/avant-de-commencer',
      '/questionnaire': '/questionnaire',
      '/finalisation': '/finalisation',
      '/resultats': '/resultats',
    },
    en: {
      '/': '/',
      '/debug/connexion': '/debug/sign-in',
      '/debug/inscription': '/debug/sign-up',
      '/avant-de-commencer': '/getting-started',
      '/questionnaire': '/questionnaire',
      '/finalisation': '/finalization',
      '/resultats': '/results',
    },
  },
};
