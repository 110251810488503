import { Stack } from '@mui/material';
import type { FC } from 'react';
import { LinkedInButton } from '../linked-in-button';
import { PoleEmploiButton } from '../pole-emploi-button';
type SocialLoginsProps = {
  onLinkedIn: () => void;
  onPoleEmploi: () => void;
  configuration?: {
    withLinkedInConnect?: boolean;
    withPoleEmploiConnect?: boolean;
  };
};
export const SocialLogins: FC<SocialLoginsProps> = props => {
  const {
    onLinkedIn,
    onPoleEmploi,
    configuration = {}
  } = props;
  const {
    withLinkedInConnect = true,
    withPoleEmploiConnect = true
  } = configuration;
  return <Stack spacing={2} data-sentry-element="Stack" data-sentry-component="SocialLogins" data-sentry-source-file="index.tsx">
      {withPoleEmploiConnect && <PoleEmploiButton onClick={onPoleEmploi} />}
      {withLinkedInConnect && <LinkedInButton onClick={onLinkedIn} />}
    </Stack>;
};